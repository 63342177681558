import React, {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useMemo,
} from "react";
import { FormControl, FormHelperText, Box } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import ValidationButtons from "core/components/ActionFeedback/ValidationButtons";
import { useTranslation } from "react-i18next";

import { useValidateTemplate } from "modules/validate/hooks/useValidateTemplate";
import { useRefuseTemplate } from "modules/validate/hooks/useRefuseTemplate";
import {
  feedbackSchema,
  actionsSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { CommentForm } from "modules/comments/schemas/commentSchemas";
import TextareaInput from "core/components/TextareaInput/TextareaInput";
import useTemplateStatus from "modules/preview/hooks/useTemplateStatus";
import { isAlphanumericText } from "../helpers/isAlphanumericText";

const ValidationForm = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const templateId = useMemo(() => Number(id), [id]);
  const { register, handleSubmit, setError, errors, getValues, clearErrors } =
    useForm<CommentForm>({
      shouldUnregister: false,
    });
  const validate = useValidateTemplate();
  const refuse = useRefuseTemplate();
  const templateStatus = useTemplateStatus();
  const endpointToUse =
    templateStatus === "PENDING_LEGAL" ? "legal" : "commercial";
  const { setFeedback } = useFeedbackStore();

  const refuseFeedbackCallback = useCallback(
    handleSubmit((data: CommentForm) => {
      refuse.mutate({ id: templateId, comment: data.comment });
    }),
    [templateId]
  );

  const validateFeedbackCallback = useCallback(
    handleSubmit((data: CommentForm) => {
      validate.mutate({ id: templateId, endpointToUse, comment: data.comment });
    }),
    [templateId, endpointToUse]
  );

  const handleAction = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    clearErrors();
    setFeedback(
      feedbackSchema.enum.warning,
      actionsSchema.enum[endpointToUse],
      validateFeedbackCallback
    );
  };

  const reset = (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const value = getValues("comment");

    if (!value || !isAlphanumericText(value)) {
      setError("comment", { type: "required" });
    } else {
      clearErrors();
      setFeedback(
        feedbackSchema.enum.warning,
        actionsSchema.enum.refuse,
        refuseFeedbackCallback
      );
    }
  };

  return (
    <Box mb="30px" mt="20px">
      <form>
        <FormControl isRequired={true}>
          <TextareaInput
            placeholder={t("info.form.placeholder")}
            name="comment"
            isRequired={true}
            ref={register}
            errorBorderColor="error"
            isInvalid={!!errors.comment}
          />
          <FormHelperText
            fontSize="xs"
            color={errors.comment ? "error" : "jumbo"}
            align="left"
            mt={0}
          >
            {t("info.form.hint")}
          </FormHelperText>
          <Box mt="20px">
            <ValidationButtons
              confirm={t("info.form.confirm")}
              cancel={t("info.form.cancel")}
              handleAction={handleAction}
              reset={reset}
              type="validator"
            />
          </Box>
        </FormControl>
      </form>
    </Box>
  );
};

export default ValidationForm;
