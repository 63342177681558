import React from "react";
import { HStack, Input, Text } from "@chakra-ui/react";

import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { GlobalMessageFormValues } from "../schemas/globalMessageFormSchema";
import WarningMessage from "core/components/WarningMessage/WarningMessage";
import useGlobalMessage from "../hooks/useGlobalMessage";

const TitleInput = () => {
  const { t } = useTranslation();
  const { control, register, errors } =
    useFormContext<GlobalMessageFormValues>();
  const { data } = useGlobalMessage();

  const globalMessageStatus = data?.status;
  const isDisabled = !!globalMessageStatus && globalMessageStatus !== "DRAFT";

  return (
    <>
      <Text fontSize="xs" mb="20px" colors="dark" textAlign="left">
        {t("global_message.form_title.label")}
      </Text>
      <HStack>
        <Controller
          control={control}
          name="title"
          style={{ borderColor: errors.title?.message ? "red" : undefined }}
          render={({ value, onChange }) => (
            <Input
              variant="outline"
              data-testid="title-input"
              name="title"
              ref={register({ required: true, minLength: 1 })}
              onChange={onChange}
              value={value}
              disabled={isDisabled}
            />
          )}
        />
      </HStack>
      {errors.title?.message && (
        <WarningMessage>{t("global_message.form_title.error")}</WarningMessage>
      )}
    </>
  );
};

export default TitleInput;
