import React from "react";
import {
  FormLabel,
  Box,
  Heading,
  Textarea,
  Button,
  FormControl,
  FormHelperText,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { usePostContactForm } from "modules/user/profile/hooks/usePostContactForm";
import {
  ContactForm,
  contactFormValuesSchema,
} from "modules/user/profile/schemas/contactFormSchema";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import DraftIcon from "core/components/InterfaceIcons/DraftIcon";
import { zodResolver } from "@hookform/resolvers/zod";

const ContactAdminForm = () => {
  const { t } = useTranslation();
  const { mutate } = usePostContactForm();
  const { register, handleSubmit, errors, reset } = useForm<ContactForm>({
    resolver: zodResolver(contactFormValuesSchema),
  });
  const { setFeedback } = useFeedbackStore();

  const handleSaveContactForm = (data: ContactForm) => {
    mutate(data.message, {
      onSuccess: () => {
        setFeedback(
          feedbackSchema.enum.success,
          actionsSchema.enum.contactMessage
        );
        reset();
      },
    });
  };

  return (
    <Box>
      <form>
        <FormControl>
          <Heading as="h2" size="md" my="10px" textAlign="left">
            {t("contact_admin.title")}
          </Heading>
          <FormLabel my="20px"> {t("contact_admin.label")}</FormLabel>
          <Textarea
            borderColor="silver"
            name="message"
            height="300px"
            ref={register}
            required={true}
            errorBorderColor="error"
            isInvalid={!!errors.message}
          />
          {errors.message && (
            <FormHelperText fontSize="xs" color="error" align="left" mt={0}>
              {t("comments.form.error")}
            </FormHelperText>
          )}
          <Button
            mt="50px"
            onClick={handleSubmit(handleSaveContactForm)}
            leftIcon={<DraftIcon />}
            variant="primary-solid"
          >
            {t("contact_admin.submit")}
          </Button>
        </FormControl>
      </form>
    </Box>
  );
};

export default ContactAdminForm;
