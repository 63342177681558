import React from "react";
import {
  FormControl,
  Textarea,
  FormHelperText,
  Box,
  FormLabel,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CommentForm } from "modules/comments/schemas/commentSchemas";
import { usePostComment } from "modules/comments/hooks/usePostComment";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import CommentIcon from "core/components/InterfaceIcons/CommentIcon";

const CommentsForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset, setError } =
    useForm<CommentForm>();
  const { mutate } = usePostComment();
  const { id } = useParams<RouteIdParam>();

  const handleComment = (data: CommentForm) => {
    if (!data.comment) {
      setError("comment", { type: "required" });
    } else {
      const comment = { ...data, templateId: Number(id) };
      mutate(comment);
      reset();
    }
  };

  return (
    <Box mb="20px" mt="20px">
      <form>
        <FormControl>
          <FormLabel>{t("comments.form.label")}</FormLabel>
          <Textarea
            placeholder={t("comments.form.placeholder")}
            name="comment"
            ref={register}
            required={true}
            errorBorderColor="error"
            isInvalid={!!errors.comment}
          />
          {errors.comment && (
            <FormHelperText fontSize="xs" color="error" align="left" mt={0}>
              {t("comments.form.error")}
            </FormHelperText>
          )}
          <Box mt="20px">
            <Button
              onClick={handleSubmit(handleComment)}
              leftIcon={<CommentIcon />}
              variant="secondary-solid"
            >
              {" "}
              {t("comments.form.button")}
            </Button>
          </Box>
        </FormControl>
      </form>
    </Box>
  );
};

export default CommentsForm;
