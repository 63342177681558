export enum ENDPOINTS {
  ARCHIVE = "/api/v1/workflow/:id/archive",
  BACK_TO_DRAFT = "/api/v1/workflow/:id/back_to_draft",
  COLORSCHEME = "/api/v1/color_schemes/:id",
  COLORSCHEMES = "/api/v1/color_schemes",
  COMMENTS = "/api/v1/templates/:id/comments",
  COMMERCIAL_VALIDATE = "/api/v1/workflow/:id/validation/commercial",
  CONTACT = "/api/v1/help/contact",
  COUNTRIES = "/api/v1/countries",
  DASHBOARDS = "/api/v1/monitor/dashboards",
  EMERGENCY_STOP = "/api/v1/regions/:region/manage",
  FINALIZE = "/api/v1/workflow/:id/finalize",
  GLOBALS = "/api/v1/globals",
  GLOBAL = "/api/v1/globals/:id",
  IMAGE = "/api/v1/images/:id",
  IMAGE_URL = "/api/v1:imageUrl",
  IMAGES = "/api/v1/images",
  MSG_INSPECTION = "/api/v1/monitor/messages?rsmid=:id",
  TPL_INSPECTION = "/api/v1/monitor/templates?region=:region",
  TPL_INSPECTION_ALL_REGIONS = "/api/v1/monitor/templates",
  CAMPAIGN_INSPECTION = "/api/v1/monitor/campaigns?region=:region",
  CAMPAIGN_INSPECTION_ALL_REGIONS = "/api/v1/monitor/campaigns",
  LEGAL_VALIDATE = "/api/v1/workflow/:id/validation/legal",
  LOGIN = "/api/v1/login",
  LOGIN_OKTA_POST = "/auth/openid", // POST sur l'api
  LOGOUT = "/api/v1/logout",
  MAIL = "/api/v1/users/email",
  PALETTE = "/api/v1/color_schemes/:id",
  PALETTES = "/api/v1/color_schemes",
  PRODUCTION = "/api/v1/globals/:id/finalize",
  QR_CODES = "/api/v1/qrcodes",
  QR_CODE = "/api/v1/qrcodes/:id",
  REFUSE = "/api/v1/workflow/:id/validation/refuse",
  REGIONS = "/api/v1/regions",
  REGIONS_STATUS = "/api/v1/regions/status",
  REQUEST_VALIDATE = "/api/v1/workflow/:id/validation/request",
  ROLES = "/api/v1/roles",
  SAVE = "/api/v1/templates/:id",
  SELFCARE = "/api/v1/user",
  STOP = "/api/v1/workflow/:id/stop",
  TARGET = "/api/v1/workflow/:id/target",
  TEMPLATE = "/api/v1/templates/:id",
  TEMPLATES = "/api/v1/templates",
  TEST = "/api/v1/workflow/:id/test",
  USER = "/api/v1/users/:id",
  USER_TEST_CARD = "/api/v1/users/:id/test_cards/:test_card_id",
  USER_TEST_CARDS = "/api/v1/users/:id/test_cards",
  USERS = "/api/v1/users",
  VALIDATION = "/api/v1/globals/:id/validation",
  RESET_PASSWORD = "/api/v1/reset_password",
}
