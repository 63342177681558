export enum PATHS {
  LOGIN = "/login",
  OKTA_REDIRECT = "/auth/openid",
  OKTA_CB = "/auth/cb",
  RESET_CREDENTIAL_EMAIL_STEP = "/reset_password/email",
  RESET_CREDENTIAL_PASSWORD_STEP = "/reset_password",
  RESET_CREDENTIAL_SUCCEED = "/reset_password/succeed",
  HOME = "/",
  TEMPLATES = "/templates",
  CREATE = "/create",
  TEMPLATE = "/template/:id",
  FINALIZATION = "/finalization/:id",
  TARGET = "/target/:id",
  TEST = "/test/:id",
  NO_RIGHT = "/noRight",
  USERS = "/administration/manage-user",
  CREATE_COLORSCHEME = "/administration/color_schemes",
  COLORSCHEME = "/administration/color_scheme/:id",
  ASSETS = "/administration/manage-assets",
  CREATE_USER = "/administration/create-user",
  DUPLICATE_USER = "/administration/duplicate-user/:id",
  USER = "/administration/user/:id",
  ADMINISTRATION = "/administration",
  PROFILE = "/profile",
  ARCHIVE = "/templates/:id/archive",
  IMAGES = "/images",
  QRCODES = "/qrcodes",
  MONITORING = "/supervision/meteo",
  GLOBAL_MESSAGES = "/globals",
  GLOBAL_MESSAGE = "/global/:id",
  CREATE_GLOBALMESSAGE = "/globals/create",
  INSPECTION = "/supervision/inspection",
  CAMPAIGN = "/supervision/campaign",
  METRICS = "/supervision/metrics",
  GRAPHS = "/supervision/graphs",
  EMERGENCY_STOP = "/emergency-stop",
}
