import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import { useTestCards } from "modules/test/hooks/useTestCards";
import { useTestTemplate } from "modules/test/hooks/useTestTemplate";
import Dropdown from "core/components/Dropdown/Dropdown";
import previewFormWidth from "modules/preview/constants/previewFormWidth";
import TextWithIcon from "core/components/TextWithIcon/TextWithIcon";
import useTemplate from "modules/preview/hooks/useTemplate";

export interface StorableForm {
  storable_1: string;
}

export interface TestFormInterface {
  testCard: number;
  storables_type: string;
  storables: StorableForm | null | {};
}

const TestForm = () => {
  const { t } = useTranslation();
  const data = useTestCards();
  useTemplate();
  const { id } = useParams<RouteIdParam>();
  const { handleSubmit, control, watch } = useForm<TestFormInterface>();
  const test = useTestTemplate();
  const popup_options = [
    { label: t("actions.test.form.storables.no_popup"), value: "no_popup" },
    {
      label: t("actions.test.form.storables.default_popup"),
      value: "default_popup",
    },
    {
      label: t("actions.test.form.storables.configurable_popup"),
      value: "configurable_popup",
    },
  ];
  const [configurable_popup, set_configurable_popup] = useState("");
  const handleAction = (data: TestFormInterface) => {
    const payload = {
      test_card_id: data.testCard,
      templateId: Number(id),
      storables:
        data.storables_type === "configurable_popup"
          ? { storable_1: configurable_popup }
          : data.storables_type === "default_popup"
          ? {}
          : null,
    };
    test.mutate(payload);
  };

  return (
    <Box mb="30px" mt="20px" w={previewFormWidth} ml={10}>
      <form>
        <FormControl>
          <FormLabel color="dark">{t("actions.test.form.label")}</FormLabel>
          <FormHelperText fontSize="xs" align="left" color="dark" mb="20px">
            {t("actions.test.form.hint")}
          </FormHelperText>
          {data && (
            <Controller
              control={control}
              name="testCard"
              render={({ onChange, value, ref }) => (
                <Dropdown
                  searchable
                  options={data}
                  name="testCard"
                  inputRef={ref}
                  value={data.find((option) => option.value === value)!}
                  onChange={(option) => onChange(option.value)}
                  placeholder={t("actions.test.form.placeholder")}
                  noOptions={t("actions.test.form.no_options")}
                />
              )}
            />
          )}
          <Box mt="20px">
            <Controller
              control={control}
              name="storables_type"
              render={({ onChange, value, ref }) => (
                <Dropdown
                  options={popup_options}
                  name="storables_type"
                  inputRef={ref}
                  value={
                    popup_options.find((option) => option.value === value)!
                  }
                  onChange={(option) => onChange(option.value)}
                  placeholder={t(
                    "actions.test.form.storables.popup_selector_placeholder"
                  )}
                />
              )}
            />
          </Box>
          {watch("storables_type") === "configurable_popup" && (
            <Box mt="15px">
              <Input
                variant="outline"
                type="text"
                name="text"
                size="md"
                placeholder={t("actions.test.form.storables.popup_placeholder")}
                defaultValue={configurable_popup}
                onChange={(event) => set_configurable_popup(event.target.value)}
              />
            </Box>
          )}
          <Box mt="40px">
            <TextWithIcon icon="info">
              {t("actions.test.form.info")}
            </TextWithIcon>
          </Box>
          <Box mt="30px">
            <Button
              onClick={handleSubmit(handleAction)}
              disabled={!watch("testCard")}
              variant="primary-solid"
              isLoading={test.isLoading}
            >
              {t("actions.test.form.button")}
            </Button>
          </Box>
        </FormControl>
        <Box mt="30px">
          <Link as={RouterLink} to="/profile" textStyle="labelSmall">
            {t("actions.test.tooltip.button")}
          </Link>
        </Box>
      </form>
    </Box>
  );
};

export default TestForm;
