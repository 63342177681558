import React, { forwardRef } from "react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import useDisableForm from "modules/preview/hooks/useDisableForm";

export type FormHourType = "hours" | "minutes";

const Stepper = styled(NumberInputStepper)`
  right: -15px;
`;

const Input = styled(NumberInputField)`
  margin: 0;
  padding: 0;
  width: 35px;
`;

const Increment = styled(NumberIncrementStepper)`
  width: 18px;
  border: none;
`;

const Decrement = styled(NumberDecrementStepper)`
  width: 18px;
  border: none;
`;

interface Props {
  type: FormHourType;
  onChange(e: string, t: FormHourType): void;
  value: string;
}

const HourInput = forwardRef<HTMLInputElement, Props>(
  ({ type, value, onChange }, ref) => {
    const disabled = useDisableForm();
    return (
      <NumberInput
        step={type === "hours" ? 1 : 15}
        ref={ref}
        allowMouseWheel
        variant="unstyled"
        value={value}
        onChange={(value: string) => onChange(value, type)}
        disabled={disabled}
      >
        <Input pointerEvents="none" />
        <Stepper>
          <Increment />
          <Decrement />
        </Stepper>
      </NumberInput>
    );
  }
);

export default HourInput;
