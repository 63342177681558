import React from "react";
import {
  FormControl,
  Box,
  FormLabel,
  Input,
  FormHelperText,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";

import DiffusionForm from "./DiffusionForm";
import previewFormWidth from "modules/preview/constants/previewFormWidth";
import TextareaInput from "core/components/TextareaInput/TextareaInput";
import { RouteIdParam } from "modules/shared/schemas/paramsSchema";
import TargetIcon from "core/components/InterfaceIcons/TargetIcon";
import { useTargetTemplate } from "modules/target/hooks/useTargetTemplate";
import useFeedbackStore from "modules/feedback/hooks/useFeedbackStore";
import {
  actionsSchema,
  feedbackSchema,
} from "core/components/ActionFeedback/schemas/actionSchemas";
import getTargetRequestFromFormValues from "modules/target/helpers/getTargetRequestFromFormValues";
import {
  TargetFormValues,
  targetFormValuesSchema,
} from "modules/target/schemas/formSchemas";

const TargetForm = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteIdParam>();
  const target = useTargetTemplate();
  const { setFeedback } = useFeedbackStore();
  const now = new Date();
  const defaultValues = {
    startDate: now,
    endDate: now,
    message: "",
    id,
  };
  const { register, handleSubmit, errors, control } = useForm<TargetFormValues>(
    {
      defaultValues,
      resolver: zodResolver(targetFormValuesSchema),
    }
  );

  const handleAction = (data: TargetFormValues) => {
    const targetRequest = getTargetRequestFromFormValues(data);
    target.mutate(targetRequest, {
      onSuccess: () => {
        setFeedback(feedbackSchema.enum.success, actionsSchema.enum.target);
      },
    });
  };

  return (
    <Box
      mb="30px"
      mt="20px"
      w={previewFormWidth}
      ml={10}
      data-testid="target-form"
    >
      <form>
        <FormControl>
          <FormLabel color="jumbo">
            {t("actions.target.form.template")}
          </FormLabel>
          <Input
            borderColor="silver"
            bgColor="zircon"
            color="jumbo"
            variant="outline"
            value={id}
            isReadOnly
            name="id"
            ref={register}
            id="id"
          />
          <FormLabel mt="30px" color="abbey">
            {t("actions.target.form.target.label")}
          </FormLabel>
          <TextareaInput
            placeholder={t("actions.target.form.target.placeholder")}
            name="message"
            isRequired={true}
            ref={register}
            errorBorderColor="error"
            isInvalid={!!errors.message}
            id="message"
          />
          {errors.message && (
            <FormHelperText fontSize="xs" color="error" align="left" mt={0}>
              {t("actions.target.form.target.error")}
            </FormHelperText>
          )}
          <DiffusionForm control={control} />
          <Box mt="40px">
            <Button
              variant="secondary-solid"
              onClick={handleSubmit(handleAction)}
              leftIcon={<TargetIcon />}
            >
              {t("actions.target.form.button")}
            </Button>
          </Box>
        </FormControl>
      </form>
    </Box>
  );
};

export default TargetForm;
