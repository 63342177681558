import React from "react";
import { Box, Heading, Text, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import ScreenContainer from "modules/shared/components/ScreenContainer/ScreenContainer";

function Administration() {
  const { t } = useTranslation();
  return (
    <ScreenContainer>
      <Box
        display="flex"
        flexDirection="column"
        h="100%"
        align="center"
        justifyContent="center"
        p={20}
      >
        <Heading as="h1" size="2xl">
          {t("administration.title")}
        </Heading>
        <Text as="h2" textStyle="h2" my="20px">
          {t("administration.label")}
        </Text>
        <Link as={RouterLink} to="/">
          {t("administration.link")}
        </Link>
      </Box>
    </ScreenContainer>
  );
}

export default Administration;
