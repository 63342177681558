import create, { State } from "zustand";
import { persist } from "zustand/middleware";
import useInitializationStore from "modules/shared/hooks/useInitializationStore";
interface TokenState extends State {
  token: string | null;
  setToken: (token: string) => void;
  resetToken: () => void;
}

const useTokenStore = create<TokenState>(
  persist(
    (set) => ({
      token: null,
      setToken: (token) => set({ token }),
      resetToken: () => set({ token: null }),
    }),
    {
      name: "token", // unique name
      onRehydrateStorage: () => () => {
        useInitializationStore.getState().setInitialized();
      },
    }
  )
);

export default useTokenStore;
