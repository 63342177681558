import React, { useState } from "react";
import {
  Flex,
  VStack,
  Text,
  useRadioGroup,
  Heading,
  Input,
  FormLabel,
  FormControl,
  FormHelperText,
  Box,
} from "@chakra-ui/react";

import { useFormContext, useController } from "react-hook-form";

import RadioCard from "modules/create/components/ui/RadioCard";
import { radioCardSizeSchema } from "modules/create/schemas/formSchema";
import {
  CustomizedColorSchemeForm,
  formColorKeySchema,
} from "modules/colorSchemes/schemas/formSchemas";
import { useTranslation } from "react-i18next";
import ColorPicker from "./ColorPicker";
import { ColorName } from "modules/colorSchemes/schemas/createColorSchemeApiSchemas";

const ColorsForm = () => {
  const context = useFormContext<CustomizedColorSchemeForm>();

  const defaultColorName = formColorKeySchema.options[0];

  const [colorName, setColorName] = useState<ColorName>(defaultColorName);

  const { t } = useTranslation();

  const {
    field: { ref, ...colorPickerProps },
  } = useController({
    name: colorName,
    control: context.control,
  });

  const { getRootProps, getRadioProps } = useRadioGroup({
    defaultValue: defaultColorName,
    value: colorName,
    onChange: (value) => setColorName(value as ColorName),
  });

  const group = getRootProps();

  return (
    <Flex justifyContent="space-between" {...group}>
      <Box>
        <Heading as="h3" variant="h3" mb="10px">
          {t("assets.color_scheme.informations")}
        </Heading>
        <Flex>
          <FormControl id="name" isRequired={true}>
            <FormLabel>{t("assets.color_scheme.name")}</FormLabel>
            <Input
              variant="outline"
              name="name"
              ref={context.register}
              isInvalid={!!context.errors.description}
            />
            {context.errors.description && (
              <FormHelperText fontSize="xs" color="error">
                {t("user-cards.form.error.required")}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl id="description" isRequired={true} ml="30px">
            <FormLabel textStyle="label">
              {t("assets.color_scheme.description")}
            </FormLabel>
            <Input
              variant="outline"
              name="description"
              ref={context.register}
              isInvalid={!!context.errors.description}
              w="500px"
              maxlength="255"
            />
            {context.errors.description && (
              <FormHelperText fontSize="xs" color="error">
                {t("user-cards.form.error.required")}
              </FormHelperText>
            )}
          </FormControl>
        </Flex>
        <Box mt="40px">
          <Heading as="h3" variant="h3">
            {t("assets.color_scheme.custom_colors")}
          </Heading>
          <Text mt="5px">{t("assets.color_scheme.custom_description")}</Text>
          <Flex>
            {formColorKeySchema.options.map((colorName) => {
              const radio = getRadioProps({
                value: colorName,
              });

              return (
                <VStack width="120px" key={colorName}>
                  <RadioCard
                    size={radioCardSizeSchema.enum.smallRounded}
                    {...radio}
                    roundedColors={
                      context
                        ? [context.watch(colorName), context.watch(colorName)]
                        : ["#000000", " #000000"]
                    }
                  >
                    <Text fontSize="sm" textAlign="center">
                      {context.watch(colorName)
                        ? context.watch(colorName)
                        : "#"}
                    </Text>
                  </RadioCard>
                </VStack>
              );
            })}
          </Flex>
        </Box>
      </Box>
      <VStack align="left">
        <Heading as="h3" variant="h3">
          {t("assets.color_scheme.color_picker")}
        </Heading>
        <Text maxWidth="290px">
          {t("assets.color_scheme.color_picker_description")}
        </Text>
        <ColorPicker {...colorPickerProps} />
      </VStack>
    </Flex>
  );
};

export default ColorsForm;
