import React from "react";
import { Flex } from "@chakra-ui/react";
import BannerSwitch from "../ui/BannerSwitch";
import BannerPositionForm from "./BannerPositionForm";
import useIsSide from "modules/create/hooks/useIsSide";

const BannerForm = () => {
  const side = useIsSide();

  return (
    <Flex
      flexDirection="column"
      minw={80}
      align="left"
      data-testid="banner-form"
      mt={side ? 7 : 0}
    >
      <BannerSwitch />
      <BannerPositionForm />
    </Flex>
  );
};

export default BannerForm;
