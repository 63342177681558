import axios, { AxiosRequestConfig, Method } from "axios";
import * as z from "zod";
import getApiUrl from "core/helpers/env/getApiUrl";
import { RequestBody } from "modules/shared/webservices/schemas/requestSchemas";
import {
  errorResponseSchema,
  errorDataResponseSchema,
} from "modules/shared/webservices/schemas/errorsSchemas";
import useTokenStore from "modules/authentication/hooks/useTokenStore";
import { baseToast } from "modules/shared/helpers/baseToast";
import i18next from "i18next";
import { ENDPOINTS } from "modules/shared/webservices/constants/endpoints";

export function simpleRequest<T extends object>(
  endpoint: string,
  zodSchema: z.ZodArray<z.ZodObject<any, { strict: false }, T>>,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T[]>;

export function simpleRequest<T extends object>(
  endpoint: string,
  zodSchema: z.ZodArray<z.ZodObject<any, { strict: true }, T>>,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T[]>;

export function simpleRequest<T extends string>(
  endpoint: string,
  zodSchema: z.ZodArray<z.ZodString>,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T[]>;

export function simpleRequest<T extends object>(
  endpoint: string,
  zodSchema: z.ZodObject<any, { strict: false }, T>,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T>;

export function simpleRequest<T extends object>(
  endpoint: string,
  zodSchema: z.ZodObject<any, { strict: true }, T>,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T>;

export function simpleRequest<T extends null>(
  endpoint: string,
  zodSchema: z.ZodNull,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T>;

export function simpleRequest<T extends null>(
  endpoint: string,
  zodSchema: z.ZodUnion<[z.ZodNull, z.ZodString]>,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T>;

export function simpleRequest<T extends null>(
  endpoint: string,
  zodSchema: z.ZodString,
  method?: Method,
  body?: RequestBody,
  withToken?: boolean
): Promise<T>;

export async function simpleRequest(
  endpoint: string,
  zodSchema: any,
  method: Method = "get",
  body?: RequestBody,
  withToken: boolean = true
) {
  const url = getApiUrl(endpoint);
  let config: AxiosRequestConfig = { url, method };
  if (body) {
    config = {
      ...config,
      data: body,
    };
  }
  if (withToken) {
    const token = useTokenStore.getState().token;
    config = {
      ...config,
      headers: {
        "content-type":
          body instanceof FormData ? "multipart/form-data" : "application/json",
        "x-auth-token": token,
      },
    };
  }
  try {
    const response = await axios.request(config);

    // if schema is an array check each item
    if (zodSchema?._def?.t === "array") {
      const itemSchema = zodSchema?._def?.type;

      return (response.data as any[])?.reduce(
        (acc: any[], item: any, index) => {
          let result = null;

          if (itemSchema?._def?.t === "object") {
            result = itemSchema.nonstrict().safeParse(item);
          } else {
            result = itemSchema.safeParse(item);
          }

          // keep only valid items
          if (!result.success) {
            baseToast({
              id: "PARSE_WARNING",
              status: "warning",
              title: i18next.t("warnings.parse.title"),
              description: i18next.t("warnings.parse.description"),
            });
            // console.warn(`Item ${index} is not valid.`, item, result.error);
          }
          return [...acc, item];
        },
        []
      );
    }
    if (zodSchema?._def?.t === "object") {
      const result = zodSchema.nonstrict().safeParse(response.data);

      if (!result.success) {
        console.warn(result.error);
      }
      return result.data;
    } else {
      const result = zodSchema.safeParse(response.data);
      if (!result.success) {
        console.warn(result.error);
      }

      return result.data;
    }
  } catch (error) {
    if (!(error as any).response && endpoint === ENDPOINTS.SELFCARE) {
      useTokenStore.getState().resetToken();
    }
    let errorResponse;
    try {
      errorResponse = errorResponseSchema.parse((error as any).response);
    } catch (e) {
      throw new Error(`Error and unexpected error response.\n\n${e}`);
    }

    // API can return 3 kinds of error message
    const getMessage = (errorResponse: any) => {
      if (errorDataResponseSchema.check(errorResponse.data)) {
        let errorMessages: any = [];
        Object.keys(errorResponse.data.errors).map((error) => {
          const errorMessage =
            errorResponse.data.errors[error]["non_field_errors"] ??
            errorResponse.data.errors[error];
          return errorMessages.push(
            `${errorResponse.data.code} : ${error} Error message: ${errorMessage}`
          );
        });
        return errorMessages;
      } else if (typeof errorResponse.data === "string") {
        return errorResponse.data;
      }
    };

    const message = getMessage(errorResponse);

    if (typeof message === "string") {
      throw new Error(
        `Error \n${errorResponse.status} with error message\n\n${message}`
      );
    } else if (Array.isArray(message)) {
      throw new AggregateError(message).errors;
    }
  }
}
